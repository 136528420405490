/* You can add global styles to this file, and also import other style files */

.font-en {
  font-family: fantasy;
}

.font-ch {
  font-family: "Wawati SC", serif;
}

/*footer{*/
/*  width: 100%;*/
/*  padding: 0;*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*}*/

/* 覆盖bootstrap中的class start */
.btn-red-2 {
  color: #fff;
  background-color: #fc2e5e;
  border-color: #ff3341;
}

.btn-red-2:hover {
  color: #fff;
  background-color: #ff003b;
  border-color: #ff003b;
}

.btn-red-2:focus, .btn-red-2.focus {
  color: #fff;
  background-color: #ff003b;
  border-color: #ff003b;
  box-shadow: 0 0 0 0.2rem rgba(252, 92, 92, 0.5);
}

.btn-red-2.disabled, .btn-red-2:disabled {
  color: #fff;
  background-color: #f3718f;
  border-color: #f3718f;
}

/* 覆盖bootstrap中的class end */

.bg-purple {
  background-color: #5f6b81;
}

/* 解决数字1和其他数字宽度不同造成div变动 */
.monospace {
  font-family: monospace;
}

/* mail 認証 page css start */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing-demo {
  width: 22ch;
  animation: typing 2s steps(22), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}

.circle-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  float: left;
  margin: 10px;
}

.icon {
  position: absolute;
  color: #fff;
  font-size: 55px;
  top: 48px;
  left: 51px;
  transform: translate(-50%, -50%);
}

.circle {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  animation: spin 10s linear infinite;
}

.circle-wrapper:active .circle {
  animation: spin 2s linear infinite;
}

.success {
  background-color: #4BB543;
  border: 2.5px dashed #4BB543;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

.page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* mail 認証 page css end */

/* customer button background start */

.btn-purple {
  color: #fff;
  background-color: #9777fa;
  border-color: #9777fa;
}

.btn-purple:hover {
  color: #fff;
  background-color: #775ad1;
  border-color: #775ad1;
}

.btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background-color: #775ad1;
  border-color: #775ad1;
  box-shadow: 0 0 0 0.2rem rgba(99, 95, 224, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #9777fab8;
  border-color: #9777fab8;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #5C477E;
  border-color: #5C477E;
}

.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 95, 224, 0.5);
}

/* customer button background end */

/* login remember me check box start */
.gr-check-input input:checked ~ .checkbox::after {
  border-color: #9777fa !important;
  background-color: #9777fa !important;
}

.gr-check-input input:checked ~ .checkbox {
  background: #9777fa !important;
  border-color: #9777fa;
}

/* login remember me check box end */

.ng-dirty.ng-invalid {
  border-color: #f44336;
}

/* header user name style start */
.main-menu .nav-link i {
  padding-bottom: 5px !important;
}

@media (min-width: 992px) {
  .gr-menu-dropdown {
    border-top: 3px solid #2adcb2 !important;
    box-shadow: 0 52px 54px rgb(65 62 101 / 5%) !important;
  }
}

/* header user name style end */

/* 覆盖勤務表確認Table style start */
.p-datatable .p-datatable-tbody > tr > td {
  font-family: monospace !important;
  padding: 0.2rem 1rem !important;
  text-align: center !important;
  font-size: 13px !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem !important;
  text-align: center !important;
}

.p-datatable .p-datatable-header {
  padding: 0.5rem 1rem !important;
}

.p-datatable .p-datatable-footer {
  padding: 0.5rem 1rem !important;
}

/* 覆盖勤務表確認Table style end */

/* a 标签添加下划线 */
.text-decoration {
  text-decoration: underline !important;
}

.light-grey {
  background: #0000003b !important
}

/* 勤務表確認ページ の p-calendar start */
p-table .p-inputtext {
  height: 40px !important;
}

p-table .p-datepicker-year::after {
  content: '年' !important;
}

p-table .p-inputtext {
  font-family: monospace !important;
  text-align: center !important;
}

p-table .p-inputtext {
  background: #f8f9fa !important;
  cursor: pointer !important;
}

/* 勤務表確認ページ の p-calendar end */

/* progress bar start */
.progress-bar {
  height: 3px !important;
  background: #5f6b81 !important;
  border-radius: 0 !important;
}

.p-progressbar .p-progressbar-value {
  background: #008eff !important;
}

/* progress bar end */

/* dialog style start */
.default-height {
  min-height: 109px !important;
  /* 109px 等于 rows="5" */
}

.p-dialog .p-dialog-header {
  padding-bottom: 0 !important;
}

.p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup textarea:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media (max-width: 992px) {
  .dialog-width {
    width: 92vw !important;
  }
}

@media (min-width: 993px) {
  .dialog-width {
    width: 55vw !important;
  }
}

/* dialog style end */

/* all */
input::placeholder, textarea::placeholder {
  color: #a9b3bd !important;
}

/* 勤務管理表のpopup start */
.p-confirm-popup {
  max-width: 400px !important;
  min-width: 300px !important;
}

.p-confirm-popup-footer {
  display: none !important;
}

.p-confirm-popup .p-confirm-popup-message {
  margin-left: 0.2rem !important;
}

/* 勤務管理表のpopup end */

/* p table start */

.p-datatable table {
  width: auto !important;
}

.m-w-100 {
  min-width: 114.6px !important;
}

.m-w-150 {
  min-width: 150px !important;
}

/* p-table 中的 p-calendar */
.p-datatable-scrollable-footer, .p-datatable-scrollable-header {
  overflow: initial !important;
}

/* p table end */

/* 禁用时，鼠标显示禁用状态 */
.not-allowed {
  cursor: not-allowed !important;
}

.text-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 80px !important;
}
